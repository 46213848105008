import React, { createContext, useEffect, useReducer } from 'react'
import jwtDecode from 'jwt-decode'
import SplashScreen from 'src/components/SplashScreen'
import axios from 'src/utils/axios'
import { gql } from '@apollo/client'
import request from 'graphql-request'

const initialAuthState = {
  isAuthenticated: true,
  isInitialised: false,
  user: null
}

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false
  }

  const decoded = jwtDecode(accessToken)
  const currentTime = Date.now() / 1000

  return decoded.exp > currentTime
}

const setSession = (accessToken, email) => {
  if (accessToken && email) {
    localStorage.setItem('accessToken', accessToken)
    localStorage.setItem('email', email)
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  } else {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('email')
    localStorage.removeItem('orgID')
    delete axios.defaults.headers.common.Authorization
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      }
    }
    case 'LOGIN': {
      const { user } = action.payload

      return {
        ...state,
        isAuthenticated: true,
        user
      }
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      }
    }
    case 'REGISTER': {
      const { user } = action.payload

      return {
        ...state,
        isAuthenticated: true,
        user
      }
    }
    default: {
      return { ...state }
    }
  }
}

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  getOrgId: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve()
})

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState)
  const endpoint = process.env.REACT_APP_END_POINT
  //Login
  const login = async (
    email,
    password,
    setStatus,
    setSubmitting,
    setErrors
  ) => {
    const authenticateQuery = gql`
      mutation AuthenticateUser($email: String!, $password: String!) {
        authenticate(input: { email: $email, password: $password }) {
          auth {
            jwtToken
          }
        }
      }
    `
    const variables = {
      email,
      password
    }
    setSubmitting(true)

    request(endpoint, authenticateQuery, variables).then(
      (response) => {
        const headerToken = response.authenticate.auth.jwtToken
        if (headerToken) {
          setSession(headerToken, email)
          console.log('headerToken', headerToken)
          getOrgId(email, headerToken, setStatus, setSubmitting, setErrors)
        } else {
          setStatus({ success: false })
          setSubmitting(false)
          setErrors({ submit: 'Enter valid credentials.' })
        }

        // setStatus({success: true});
        // setSubmitting(false);
      },
      (error) => {
        console.log(error)
        setStatus({ success: false })
        setErrors({
          submit:
            error === 'Something went wrong'
              ? "We're having trouble connecting. Please try agian later"
              : error.message
        })
        setSubmitting(false)
      }
    )
  }
  //Fetch - OrgId
  const getOrgId = async (
    email,
    headerToken,
    setStatus,
    setSubmitting,
    setErrors
  ) => {
    const fetchOrgIdQuery = gql`
      query getOrganizationId($email: String!) {
        orgUserByEmail(email: $email) {
          organizationId
        }
      }
    `
    const variable = { email }
    const requestHeaders = {
      authorization: `Bearer ${headerToken}`
    }
    request(endpoint, fetchOrgIdQuery, variable, requestHeaders).then(
      (response) => {
        console.log('getOrgId', response)
        dispatch({
          type: 'LOGIN',
          payload: {
            user: {
              email: email,
              name: email,
              orgID: response.orgUserByEmail.organizationId
            }
          }
        })
        localStorage.setItem('orgID', response.orgUserByEmail.organizationId)
        setSubmitting(false)
        setStatus({ success: true })
      },
      (error) => {
        console.log('getOrgId error', error)
        setStatus({ success: false })
        setErrors({
          submit:
            error === 'Something went wrong'
              ? "We're having trouble connecting. Please try agian later"
              : error.message
        })
        setSubmitting(false)
      }
    )
  }

  //Logout
  const logout = () => {
    setSession(null)
    dispatch({ type: 'LOGOUT' })
  }

  //Register
  const register = async (
    orgId,
    email,
    password,
    setStatus,
    setSubmitting,
    setErrors,
    setShowToast,
    history,
    resetForm
  ) => {
    const registerUserQuery = gql`
      mutation RegisterUser(
        $email: String!
        $organizationId: UUID!
        $password: String!
      ) {
        registerUser(
          input: {
            email: $email
            password: $password
            organizationId: $organizationId
          }
        ) {
          orgUser {
            createdAt
          }
        }
      }
    `
    const variables = {
      email: email,
      password: password,
      organizationId: orgId
    }

    request(endpoint, registerUserQuery, variables).then(
      (response) => {
        if (response) {
          /*if (response.data.message) {
            setStatus({success: false});
            setErrors({submit: response.data.message});
            setSubmitting(false);
          } else {
            setStatus({success: true});
            resetForm({})
            setShowToast(true)
          }*/
          history.push('/login')
        }
      },
      (error) => {
        console.log(error)
        // setStatus({success: false});
        setErrors({
          submit:
            error === 'Something went wrong'
              ? "We're having trouble connecting. Please try agian later"
              : error.message
        })
        // setSubmitting(false);
      }
    )
  }

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken')
        const email = window.localStorage.getItem('email')
        const organizationId = window.localStorage.getItem('organizationId')

        console.log(
          "window.localStorage.getItem('accessToken')...",
          window.localStorage.getItem('accessToken')
        )

        if (accessToken && isValidToken(accessToken)) {
          // const userId  = jwt.verify(accessToken, JWT_SECRET);
          // console.log(userId)
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user: {
                email: email,
                name: email,
                organizationId: organizationId
              }
            }
          })
        } else {
          localStorage.removeItem('accessToken')
          localStorage.removeItem('email')
          localStorage.removeItem('organizationId')
          delete axios.defaults.headers.common.Authorization
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          })
        }
      } catch (err) {
        console.error(err)
        return [500, { message: 'Internal server error' }]
      }
    }

    initialise()
  }, [])

  if (!state.isInitialised) {
    return <SplashScreen />
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthContext
