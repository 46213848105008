export const APP_VERSION = '1.0.0';

export const AppConstants = {
    APP_NAME: 'Food Training Application'
};

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
    LIGHT: 'LIGHT',
    ONE_DARK: 'ONE_DARK',
    UNICORN: 'UNICORN'
};

export const FETCH_TIME_INTERVAL = 20000;

